<template>
  <div className="container">
    <line-chart
      :data="chartData"
      :width="'1000px'"
      :ytitle="'Số lượng'"
      :xtitle="'Module được đồng bộ'"
      :height="'400px'"
      loading="Đang tải..."
      :download="true"
      :curve="false"
      thousands=","
    ></line-chart>
  </div>
</template>

<script>
export default {
  props: ['chartData'],
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
